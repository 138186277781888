import {Controller} from "@hotwired/stimulus"
import QRCode from "qrcode";
import {Base64} from 'js-base64';

export default class extends Controller {
  async connect() {
    const url = `https://weallwitness.com/fuwuhao/login?redirect=${Base64.encode('https://rails.weallwitness.com/', true)}`
    document.getElementById('qrcode_img').src = await QRCode.toDataURL(url)
  }
}
