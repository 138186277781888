import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="add-chat-member"
export default class extends Controller {
  static targets = ['selected']
  selectedUsers = []

  connect() {
  }

  toggleSelection(event) {
    if (event.target.checked) {
      if (this.selectedUsers.length === 5) {
        event.target.checked = false
        alert("一次性最多只能添加五个成员")
        return
      }
      const image = document.createElement("img")
      image.src = event.params.user.avatar
      image.className = "w-8 h-8 rounded-full ml-2 my-2"
      image.id = `selected-${event.params.user.id}`
      this.selectedTarget.appendChild(image)
      this.selectedUsers.push(event.params.user.id)
    } else {
      this.selectedTarget.removeChild(document.getElementById(`selected-${event.params.user.id}`))
      this.selectedUsers.remove(event.params.user.id)
    }
  }

  confirmAdd(event) {
    const data = {
      chat_id: event.params.chatId,
      user_ids: this.selectedUsers
    }

    window.post(`/chats/${event.params.chatId}/members`, data)
      .then(_ => {
        window.location = `/chats/${event.params.chatId}/detail`
      })
  }
}
