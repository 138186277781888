import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "send" ]

  reset() {
    this.element.reset();
  }

  enableSubmitButton() {
    this.sendTarget.disabled = false
  }
}
