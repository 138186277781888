function getToken() {
  return sessionStorage.getItem("current_user_jwt_token")
}

function getCXRFToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}

window.get = (url) =>
  fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json', Authorization: `Bearer ${getToken()}`, 'X-CSRF-Token': getCXRFToken() },
  }).then((res) => res.json())

window.post = (url, data, headers) =>
  fetch(url, {
    method: 'POST',
    headers: Object.assign({ 'Content-Type': 'application/json', Accept: 'application/json', Authorization: `Bearer ${getToken()}`, 'X-CSRF-Token': getCXRFToken() }, headers),
    body: JSON.stringify(data),
  })

window.old_url = path => `https://weallwitness.com/${path}`

window.hiddenValue = (selector) => document.querySelector(selector).value

window.el = document.querySelector

Element.prototype.hide = function() {
  this.style.display = 'none';
}

Element.prototype.show = function(display) {
  this.style.display = display || 'block';
}

Array.prototype.remove = function() {
  let what, a = arguments, L = a.length, ax
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};