/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.


import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

// console.log("bind phone")
// document.addEventListener('DOMContentLoaded', () => {
document.addEventListener('turbo:load', (e) => {
  // console.log("bind phone turbo load")
  const element = document.getElementById("bindphone");
  if (!element) return

  const token = document.querySelector("#jwt_token").value
  const app = new Vue({
    el: "#bindphone",
    data: {phone: '', sms_code: '', sms_code_error_msg: '', counting_down: false, resend_counting_down: 0, count_down_interval_id: null},
    methods: {
      sendSms() {
        const body = {phone: this.phone}
        post("/bindphone/send_sms", body, {Authorization: `Bearer ${token}`})
          .then(res => res.json())
          .then(res => {
            console.log(res)
            if (res.result_code === 0) {
              this.startCountingDown()
            }
          })
      },
      bind() {
        post("/bindphone/create", {phone: this.phone, sms_code: this.sms_code},{Authorization: `Bearer ${token}`} )
          .then(res => res.json())
          .then(res => {
            if (res.result_code === 0) {
              location.href = "/settings"
              return
            }
            this.sms_code_error_msg = res.sms_code_error_msg
          })
      },
      startCountingDown() {
        this.counting_down = true
        this.resend_counting_down = 60
        this.count_down_interval_id = setInterval(() => {
          this.resend_counting_down -= 1
          if (this.resend_counting_down === 0) {
            this.counting_down = false
            clearInterval(this.count_down_interval_id)
            this.count_down_interval_id = null
          }
        }, 1000)
      }
    }
  })
})


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
