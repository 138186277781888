import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="scroll"
export default class extends Controller {
  connect() {
    const messages = document.getElementById("messages");

    const callback = (mutationsList) => {
      if (mutationsList.length === 1 && mutationsList[0].target === messages) {
        this.scroll2bottom(messages);
      }
    };

    this.observer = new MutationObserver(callback);
    this.observer.observe(messages, {attributes: false, childList: true, subtree: true});
  }

  disconnect() {
    this.observer.disconnect();
  }

  scroll2bottom(element) {
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }
}
