// Entry point for the build script in your package.json

// require('@rails/activestorage').start()
// import './channels'

// import * as Turbo from '@hotwired/turbo'

import "@hotwired/turbo-rails"
import "./controllers";

import 'alpine-turbo-drive-adapter'
import 'alpinejs'
import './common/global_utils'
import './pages/bind_phone'
import './pages/login'
import './pages/open_in_wechat'
