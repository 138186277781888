/* eslint no-console: 0 */
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbo:load', (e) => {
  const el = document.getElementById("login_app")
  if (!el) return

  new Vue({
    el,
    data: {
      phone: '',
      sms_code: '',
      error_msg: '',
      counting_down: false,
      resend_counting_down: 0,
      count_down_interval_id: null
    },
    created() {
      const state = document.getElementById('wechat_login_state').value
      new WxLogin({
        self_redirect: false,
        id:"wechat_login_container",
        appid: "wx9443d9f9b515d6d0",
        scope: "snsapi_login",
        redirect_uri: "https%3A%2F%2Fweallwitness.com%2Fwechat_login",
        state: state,
        style: "black",
        href: ""
      });
    },
    methods: {
      sendSms() {
        const body = {phone: this.phone}
        post("/login/send_sms", body)
          .then(res => res.json())
          .then(res => {
            // debugger
            if (res.result_code === 0) {
              this.startCountingDown()
            } else {
              this.error_msg = res.msg
            }
          })
          .catch(e => {
            this.error_msg = e.toString()
          })
      },
      login() {
        post("/login", {phone: this.phone, sms_code: this.sms_code})
          .then(res => res.json())
          .then(res => {
            if (res.result_code === 0) {
              location.href = "/"
              return
            }
            this.error_msg = res.msg
          })
      },
      startCountingDown() {
        this.counting_down = true
        this.resend_counting_down = 60
        this.count_down_interval_id = setInterval(() => {
          this.resend_counting_down -= 1
          if (this.resend_counting_down === 0) {
            this.counting_down = false
            clearInterval(this.count_down_interval_id)
            this.count_down_interval_id = null
          }
        }, 1000)
      }
    }
  })
})
