import { Controller } from "@hotwired/stimulus"
import { Dropzone } from "dropzone"
import COS from "cos-js-sdk-v5"
import Compressor from "compressorjs"

// Connects to data-controller="upload-image"
export default class extends Controller {
  static targets = ["presendImagePreview", "presendImagePreviewModal", "imageSelector"]
  connect() {
    const dropzone = new Dropzone("div[data-identifier='chat-container']", { url: "/", autoProcessQueue: false, previewsContainer: false, thumbnailWidth: 272 * 2, thumbnailHeight: null })
    dropzone.on("thumbnail", (file, dataUrl) => {
      this.previewBeforeSend(file, dataUrl)
    })
    this.preloadImageUploadSign()
  }

  stopPresendPreview() {
    this.presendImagePreviewModalTarget.hide()
    this.presendImagePreviewTarget.src = ""
    this.imageSelectorTarget.value = ""
  }

  previewBeforeSend(file, dataUrl) {
    this.file = file
    this.presendImagePreviewModalTarget.show()
    if (dataUrl) {
      this.presendImagePreviewTarget.src = dataUrl
    } else {
      const reader = new FileReader()
      reader.addEventListener("load", () => (this.presendImagePreviewTarget.src = reader.result), false)
      reader.readAsDataURL(file)
    }
  }

  imageSelected(event) {
    const file = event.target.files[0]
    if (!file) return

    const controllerThis = this
    new Compressor(file, {
      strict: true,
      quality: 1,
      maxWidth: 1080,
      maxHeight: 1080,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        const smaller = result.size < file.size ? result : file
        controllerThis.previewBeforeSend(smaller)
      },

      error(err) {
        console.log(err.message)
      },
    })
  }

  preloadImageUploadSign() {
    this.image_upload_sign_expiry = new Date().getTime() + 600 * 1000
    get("https://weallwitness.com/api/sign/cos_upload")
      .then((res) => res.data.sign)
      .then((sign) => {
        this.image_upload_sign = sign
      })
  }

  async performUpload() {
    try {
      const url = await this.uploadImage2TencentCOS(this.getImageName())
      console.log(url)
      this.stopPresendPreview()
      const chat_id = hiddenValue("#chat_id")
      post(`/chats/${chat_id}/messages/create_image`, { url })
    } catch (err) {
      console.log(err)
      this.error_msg = err.response?.data?.error_msg ?? err.message
    }
  }

  selectImage() {
    // console.log("selecting")
    this.imageSelectorTarget.click()
  }

  getImageName() {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}.${this.file.name.split(".").pop()}`
  }

  async uploadImage2TencentCOS(targetName) {
    const cos = this.createCOS()
    const uploadResult = await cos.putObject({
      Bucket: "witness-1252789080",
      Region: "ap-shanghai" /* 存储桶所在地域，必须字段 */,
      Key: targetName /* 必须 */,
      Body: this.file, // 上传文件对象
    })
    return `https://${uploadResult.Location}`
  }

  createCOS() {
    return new COS({
      getAuthorization: (_, callback) => {
        if (this.image_upload_sign && new Date().getTime() < this.image_upload_sign_expiry) {
          console.log(`use preloaded sign: ${this.image_upload_sign}`)
          callback({ Authorization: this.image_upload_sign })
        } else {
          get("https://weallwitness.com/api/sign/cos_upload").then((res) => callback({ Authorization: res.data.sign }))
        }
      },
    })
  }
}
